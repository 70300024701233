import React from 'react';
import logo from './david.webp';
import './App.css';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-content">
          <img src={logo} className="App-logo" alt="logo" />
          <div>
            <h3> David Collett </h3>
            <a
              className="App-link"
              href="https://www.taxpayersparty.com.au"
              target="_blank"
              rel="noopener noreferrer"
            > public chat </a>
            <a
              className="App-link"
              href="https://aussiebuilder.com"
              target="_blank"
              rel="noopener noreferrer"
            > real estate </a>
            <a
              className="App-link"
              href="https://www.meditation2relax.com"
              target="_blank"
              rel="noopener noreferrer"
            > meditation </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
